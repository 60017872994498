import { Button, Form, Modal } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useState } from 'react';

import { AddFilled } from '@carbon/icons-react';
import { ReportDataset } from './ReportModels';
import Select from 'react-select';
import { UserProfileContext } from '../Shared/contexts/UserProfile';
import { useNavigate } from 'react-router-dom';

interface DatasetSelectionModalProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
}

const DatasetSelectionModal: FunctionComponent<DatasetSelectionModalProps> = (props: DatasetSelectionModalProps) => {
    const { showModal, setShowModal } = props;
    const userProfile = useContext(UserProfileContext);
    
    const datasetOptions: ReportDataset[] = userProfile.environmentConfig?.pbiConfig?.datasets.filter(
        (d) => !d.isPublic
    ) || [];
    const [selectedDataset, setSelectedDataset] = useState<ReportDataset | null>(null);

    const navigate = useNavigate();

    return (
        <Modal
            scrollable
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            aria-labelledby="dataset-selection-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="dataset-selection-modal">Dataset Selection</Modal.Title>
            </Modal.Header>
            <Modal.Body className="unset-overflow">
                <Form className="p-3 mb-5">
                    <Form.Group controlId="DatasetSelect" className="mb-4">
                        <Form.Label>Please select a dataset for your report</Form.Label>
                        <Select<ReportDataset, false>
                            isClearable
                            isMulti={false}
                            value={selectedDataset}
                            options={datasetOptions}
                            onChange={(option) => {
                                setSelectedDataset(option);
                            }}
                            formatOptionLabel={({ typeName }) => <div>{typeName.replace('_', ' ')}</div>}
                            menuPlacement="auto"
                            placeholder="Select a dataset..."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="link"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => selectedDataset && navigate(`/reports/add/${selectedDataset.datasetGuid}`)}
                    disabled={!selectedDataset}
                >
                    <AddFilled size={24} className="button-icon-padding" data-testid="datasetAddReport"/> Add Report
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DatasetSelectionModal;
