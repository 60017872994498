import { Dispatch, SetStateAction } from 'react';
import { FavoriteReport, ReportDataset } from '../Reports/ReportModels';

import { User } from '../TeamMembers/TeamMemberModels';

export interface SourceSystem {
    isClientTrack: boolean;
    schemaName: string;
    sourceSystemKey: number;
    sourceSystemName: string;
    sourceSystemShortName: string;
    externalShortName?: string;
    isActive: boolean;
    manualUploadsEnabled: boolean;
    sourceSystemAdmins: User[];
}

export interface Organization {
    organizationKey: number;
    organizationName: string;
    sourceSystemKey: number;
}

export interface Project {
    projectKey: number;
    projectName: string;
    organizationKey: number;
    projectTypeKey?: number;
    projectType?: HmisProjectType;
}

export interface ProjectType {
    value: number;
    text: string;
}

export interface CoC {
    coCCode: string;
    coCName: string;
    coCState: string;
}

export interface HmisProjectType {
    value: string;
    text: string;
}

export interface HmisProject {
    projectKey: number;
    projectName: string;
    projectId: string;
    organizationKey: number;
    projectTypeKey: number;
    operatingStartDate: string;
    operatingEndDate?: string;
    projectType?: HmisProjectType;
    sourceSystem?: SourceSystem;
    organization?: Organization;
}

export interface DedupeSourceSystem {
    key: number;
    shortName: string;
    isActive: boolean;
}

export enum Gender {
    Woman = 2,
    Man = 3,
    'Culturally Specific Identity' = 4,
    Transgender = 5,
    'Non-Binary' = 6,
    Questioning = 7,
    'Different Identity' = 8,
    'Woman/Man' = 9,
    'Woman/Culturally Specific Identity' = 10,
    'Woman/Transgender' = 11,
    'Woman/Non-Binary' = 12,
    'Woman/Questioning' = 13,
    'Woman/Different Identity' = 14,
    'Man/Culturally Specific Identity' = 15,
    'Man/Transgender' = 16,
    'Man/Non-Binary' = 17,
    'Man/Questioning' = 18,
    'Man/Different Identity' = 19,
    'Culturally Specific Identity/Transgender' = 20,
    'Culturally Specific Identity/Non-Binary' = 21,
    'Culturally Specific Identity/Questioning' = 22,
    'Culturally Specific Identity/Different Identity' = 23,
    'Transgender/Non-Binary' = 24,
    'Transgender/Questioning' = 25,
    'Transgender/Different Identity' = 26,
    'Non-Binary/Questioning' = 27,
    'Non-Binary/Different Identity' = 28,
    'Questioning/Different Identity' = 29,
    'More than 2 Gender Identities Selected' = 30,
    "Client Doesn't Know/Prefers Not to Answer" = 31,
    'Data Not Collected' = 32,
}

export interface UserProfile {
    favoriteReports: FavoriteReport[];
    addFavoriteReport: (favorite: FavoriteReport) => void;
    removeFavoriteReport: (favorite: FavoriteReport) => void;
    cdnToken: string;
    sourceSystems: SourceSystem[];
    setSourceSystems: Dispatch<SetStateAction<SourceSystem[]>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isEccoviaUser: boolean;
    user?: User;
    environmentConfig: EnvironmentConfig;
    failedToLoad: boolean;
}

export interface EnvironmentConfig {
    hasParallelEnvironment?: boolean;
    clientTrackUrl?: string;
    pbiConfig?: PbiConfig;
    timeZone?: string;
    editByGlobalAdminOnly?: boolean;
    enableCustomUploads?: boolean;
    syncToClientTrack?: boolean,
    enableUIDedupe?: boolean,
    supportEmail?: string;
}

export interface PbiConfig {
    standardWorkspaceId: string;
    authorWorkspaceId: string;
    authorPublicWorkspaceId: string;
    authorReportId: string;
    authorPublicReportId: string;
    dashboardReportId: string;
    datasets: ReportDataset[];
}

export interface SelectOption<T> {
    label: string;
    value: T;
}

export interface WindowDimensions {
    width: number;
    height: number;
}

export interface IdTokenClaims {
    exp: number;
    nbf: number;
    ver: string;
    iss: string;
    sub: string;
    aud: string;
    acr: string;
    nonce: string;
    iat: number;
    auth_time: number;
    email: string;
    name: string;
    given_name: string;
    family_name: string;
    extension_customerId: string;
    tid: string;
}

export enum UserRoles {
    'Global Admin' = 10,
    'Source System Admin' = 20,
    'Org Admin' = 30,
    Member = 40,
    'Project User' = 50,
}
