import { Button, Spinner } from 'react-bootstrap';
import { Edit, EditOff, Pin, PinFilled, TrashCan } from '@carbon/icons-react';
import React, {
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ReportMetadata, ReportStage } from './ReportModels';
import { Slide, toast } from 'react-toastify';
import { UserProfileContext, isGlobalAdmin, isOrgAdminOrHigher } from '../Shared/contexts/UserProfile';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ApiHelper from '../Shared/ApiHelper';
import PrintReport from './PrintReport';
import ReportPublishButton from './ReportPublishButton';
import SendReport from './SendReport';
import { sendEvent } from '../Utils/googleAnalytics4Wrapper';
import { useMsal } from '@azure/msal-react';

interface ReportActionsProps {
    editMode: boolean;
    createdByUser: boolean;
    reportMetadata?: ReportMetadata;
    setReportMetadata: Dispatch<SetStateAction<ReportMetadata | undefined>>;
}

const ReportActions: FunctionComponent<ReportActionsProps> = (props: ReportActionsProps) => {
    const { editMode, createdByUser, reportMetadata, setReportMetadata } = props;

    const { instance } = useMsal();
    const navigate = useNavigate();
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);
    const [_searchParams, setSearchParams] = useSearchParams();
    const { reportId } = useParams();

    const [isLoadingPinnedStatus, setIsLoadingPinnedStatus] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const updatePinnedStatus = useCallback(
        (newPinnedStatus: boolean) => {
            if (reportMetadata) {
                setIsLoadingPinnedStatus(true);
                api.callApi(
                    instance,
                    [process.env.REACT_APP_B2C_SCOPE ?? ''],
                    process.env.REACT_APP_NET_API_URL + `/PbiReport/selected-custom`,
                    newPinnedStatus ? 'POST' : 'DELETE',
                    JSON.stringify([{ ReportKey: reportMetadata.reportKey }])
                ).then(async (_result: Response) => {
                    setReportMetadata((prevMetadata: ReportMetadata | undefined) => {
                        if (prevMetadata) {
                            return { ...prevMetadata, isSelectedByUser: newPinnedStatus };
                        }
                    });

                    setIsLoadingPinnedStatus(false);
                });
            }
        },
        [api, instance, reportMetadata, setReportMetadata]
    );

    useEffect(() => {
        if (reportMetadata?.type === 'Public') {
            setCanEdit(isGlobalAdmin(userProfile));
        } else if (reportMetadata) {
            setCanEdit(isOrgAdminOrHigher(userProfile));
        }
    }, [reportMetadata, userProfile]);

    return (
        <div className="flex-row flex-wrap" style={{ gap: '12px' }}>
            {canEdit && (
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        setSearchParams({ edit: editMode ? 'false' : 'true' });
                        sendEvent('Report Action', 'Edit Button', 'Button');
                    }}
                    data-testid="editReportButton"
                >
                    {editMode ? (
                        <>
                            <EditOff size={24} className="button-icon-padding" /> Finish Editing
                        </>
                    ) : (
                        <>
                            <Edit size={24} className="button-icon-padding" /> Edit Report
                        </>
                    )}
                </Button>
            )}
            {!!reportMetadata && (
                <PrintReport
                    datasetId={reportMetadata.datasetGuid}
                    isPublic={reportMetadata?.type == 'Public'} 
                />
            )}
            {!!reportMetadata && (
                <SendReport
                    datasetId={reportMetadata?.datasetGuid}
                    isPublic={reportMetadata?.type == 'Public'}
                />
            )}

            {reportMetadata &&
                isOrgAdminOrHigher(userProfile) &&
                reportMetadata.type !== 'Standard' &&
                reportMetadata.stage !== ReportStage.Published && (
                    <ReportPublishButton
                        reportMetadata={reportMetadata}
                        setReportMetadata={setReportMetadata}
                        createdByUser={createdByUser}
                    />
                )}
            {reportMetadata?.type === 'Custom' && !reportMetadata?.isSelectedByUser && (
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        updatePinnedStatus(true);
                    }}
                    disabled={isLoadingPinnedStatus}
                >
                    {isLoadingPinnedStatus ? (
                        <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                    ) : (
                        <Pin size={24} className="button-icon-padding" />
                    )}
                    Pin to Reports
                </Button>
            )}
            {reportMetadata?.type === 'Custom' && reportMetadata?.isSelectedByUser && (
                <Button
                    variant="outline-primary"
                    onClick={() => {
                        updatePinnedStatus(false);
                    }}
                    disabled={isLoadingPinnedStatus}
                >
                    {isLoadingPinnedStatus ? (
                        <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                    ) : (
                        <PinFilled size={24} className="button-icon-padding" />
                    )}
                    Unpin from Reports
                </Button>
            )}
            {(reportMetadata?.stage !== ReportStage.Published || !reportMetadata?.isSelectedByUser) &&
                reportMetadata?.type !== 'Standard' &&
                isOrgAdminOrHigher(userProfile) &&
                (createdByUser || isGlobalAdmin(userProfile)) && (
                    <Button
                        variant="outline-danger"
                        disabled={isDeleting}
                        onClick={() => {
                            setIsDeleting(true);
                            api.callApi(
                                instance,
                                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                process.env.REACT_APP_NET_API_URL + `/PbiReport/${reportId}`,
                                'DELETE'
                            ).then(async (_result: Response) => {
                                navigate('/reports/mywork');
                                setIsDeleting(false);

                                toast.success('Report Deleted Successfully', {
                                    position: 'top-right',
                                    autoClose: 5000,
                                    transition: Slide,
                                    draggable: false,
                                    closeOnClick: false,
                                    theme: 'colored',
                                });
                            });
                        }}
                    >
                        {isDeleting ? (
                            <Spinner size="sm" className="button-icon-padding button-spinner" animation="border" />
                        ) : (
                            <TrashCan size={24} className="button-icon-padding" />
                        )}
                        Delete Report
                    </Button>
                )}
        </div>
    );
};

export default ReportActions;
