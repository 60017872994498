import { Button, Spinner } from 'react-bootstrap';
import React, { ReactEventHandler, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { Printer } from '@carbon/icons-react';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';

interface PrintReportProps {
    type?: 'reports' | 'usage-report';
    datasetId?: string;
    isPublic: boolean;
}

const PrintReport: React.FC<PrintReportProps> = ({ type = 'reports', datasetId = undefined, isPublic }) => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const { reportId, workspaceId } = useParams();

    const [reportUrl, setReportUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getRequestUrl = (): string => {
        switch (type) {
            case 'reports':
                return `/PbiEmbed/export/${workspaceId}/${reportId}/${datasetId}/pdf?isPublic=${isPublic}`;
            case 'usage-report':
                return '/pbiembed/export-usagereport/pdf';
        }
    };

    const handleLoad: ReactEventHandler<HTMLIFrameElement> = (event) => {
        const target = event.target as HTMLIFrameElement;

        target.focus();
        target.contentWindow?.print();
    };

    const handlePrintClick = async (): Promise<void> => {
        try {
            setReportUrl('');
            setIsLoading(true);

            const response = await api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}${getRequestUrl()}`,
                'GET'
            );

            const url = window.URL.createObjectURL(await response.blob());

            setReportUrl(url);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Button variant="outline-primary"
                disabled={isLoading}
                onClick={handlePrintClick}
                data-testid="PrintReport"
            >
                {isLoading ? (
                    <Spinner
                        variant="primary"
                        animation="border"
                        style={{ width: '20px', height: '20px', margin: '0px 2px' }}
                        data-testid="PrintReportSpinner"
                    />
                ) : (
                    <Printer size={24} />
                )}
                <span className="ml-2">Print Report</span>
            </Button>
            {!!reportUrl && (
                <iframe
                    title="print-report"
                    src={reportUrl}
                    style={{ display: 'none' }}
                    onLoad={handleLoad}
                    data-testid="PrintReportiFrame"
                />
            )}
        </div>
    );
};

export default PrintReport;
