import './SendReport.css';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import ApiHelper from '../Shared/ApiHelper';
import { Email } from '@carbon/icons-react';
import ListField from '../Shared/components/ListField';
import { createSchema } from '../Shared/Validations';
import useForm from '../Shared/hooks/useForm';
import { useMsal } from '@azure/msal-react';
import { useParams } from 'react-router-dom';

interface SendReportProps {
    type?: 'reports' | 'usage-report';
    datasetId?: string;
    isPublic: boolean;
}

const validationSchema = createSchema(({ array, string }) => ({
    emails: array().of(string().email().max(255).required()).min(1).required(),
    subject: string().max(255).required(),
    description: string().max(255).required(),
}));

const SendReport: React.FC<SendReportProps> = ({ type = 'reports', datasetId = undefined, isPublic }) => {
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const { reportId, workspaceId } = useParams();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { errors, register, handleSubmit, resetForm, setValue } = useForm({
        defaultValues: {
            emails: [],
            subject: '',
            description: '',
        },
        schema: validationSchema,
    });

    const getRequestUrl = (): string => {
        switch (type) {
            case 'reports':
                return `/PbiEmbed/email/${workspaceId}/${reportId}/${datasetId}/pdf?isPublic=${isPublic}`;
            case 'usage-report':
                return '/pbiembed/email-usagereport/pdf';
        }
    };

    const onSubmit = handleSubmit(async (data) => {
        try {
            setIsLoading(true);

            const payload = JSON.stringify({
                recipients: data.emails.join(','),
                subject: data.subject,
                plainTextBody: data.description,
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}${getRequestUrl()}`,
                'POST',
                payload
            ).then(() => {
                toast.success('Report has been sent', {
                    position: 'top-right',
                    autoClose: 3000,
                    transition: Slide,
                    draggable: false,
                    closeOnClick: false,
                    theme: 'colored',
                });
            });

            setShowModal(false);
        } finally {
            setIsLoading(false);
        }
    });

    useEffect(() => {
        resetForm();
    }, [showModal, resetForm]);

    return (
        <>
            <Button
                variant="outline-primary"
                onClick={() => setShowModal(!showModal)}
                data-testid="SendReport"
            >
                <Email size={24} />
                <span className="ml-2">Send Report</span>
            </Button>

            <Modal
                centered
                scrollable
                show={showModal}
                className="send-report__modal"
                onExited={() => setShowModal(false)}
                onHide={() => setShowModal(false)}
                data-testid="SendReportModal"
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Report</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="send-report__modal__body">
                        <ListField
                            {...register('emails')}
                            label="Emails"
                            error={errors?.emails}
                            onChange={(value) => setValue('emails', value)}
                        />

                        <Form.Group className="mb-4">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                {...register('subject')}
                            />
                            <Form.Control.Feedback type="invalid">{errors?.subject}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                {...register('description')}
                            />
                            <Form.Control.Feedback type="invalid">{errors?.description}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            variant="link"
                            onClick={() => setShowModal(false)}
                            data-testid="CancelButton"
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="send-report__button__submit"
                            disabled={isLoading}
                            data-testid="SendButton"
                        >
                            {isLoading && (
                                <Spinner
                                    variant="light"
                                    animation="border"
                                    size="sm"
                                    className="send-report__spinner"
                                    data-testid="SendEmailSpinner"
                                />
                            )}
                            <span style={{ color: isLoading ? 'transparent' : '' }}>Send</span>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default SendReport;
