import './LeftNav.css';

import { Activity, Collaborate, Dashboard, Events, Report, Settings, Tools } from '@carbon/icons-react';
import { Nav, NavbarBrand } from 'react-bootstrap';
import React, { FunctionComponent, useContext, useState } from 'react';
import {
    UserProfileContext,
    isClientTrackCustomer,
    isGlobalAdmin,
    isOrgAdminOrHigher,
    isSourceSystemAdminOrHigher,
} from '../../Shared/contexts/UserProfile';

import AppsButton from './AppsButton';
import LeftNavItem from './LeftNavItem';
import { LeftNavSubItem } from './NavigationModels';

const LeftNav: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);

    const [reportsSubItems] = useState<LeftNavSubItem[]>([
        { label: 'Admin Reports', link: '/reports/globaladmin' },
        { label: 'Reports', link: '/reports' },
        { label: 'My Work', link: '/reports/mywork' },
        { label: 'Community Reports', link: '/reports/community' },
        { label: 'Public Reports', link: '/reports/public' },
    ]);

    const monitoringSubItems = [
        { label: 'Batch Runs', link: '/monitoring/batch-runs' },
        { label: 'Report Usage Metrics', link: '/monitoring/report-usage-metrics' },
        { label: 'Sync to ClientTrack', link: '/monitoring/canonicalid' },
    ];

    const teamMembersSubItems = [
        { label: 'Team Members', link: '/teammembers' },
        { label: 'Eccovia Users', link: '/teammembers/eccovia-users' },
    ];

    return (
        <Nav className="navbar-nav sidebar">
            <NavbarBrand className="sidebar-brand sidebar-brand-dark-rounded logo-container">
                {isClientTrackCustomer(userProfile) && <AppsButton />}
                <div className="sidebar-brand-text logo-text">
                    <a href="/">
                        <img src="full-logo.svg" alt="ClientInsight" width="150" />
                    </a>
                </div>
            </NavbarBrand>

            <hr className="my-2" />

            <LeftNavItem linkUrl={'/'}>
                <Dashboard size={24} className="nav-item-contents" />
                <div className="nav-item-contents">Dashboard</div>
            </LeftNavItem>

            <LeftNavItem
                linkUrl="/reports"
                expandable
                subItems={
                    isOrgAdminOrHigher(userProfile)
                        ? reportsSubItems.filter((item) => ((item.label === 'Admin Reports' && isGlobalAdmin(userProfile)) || item.label !== 'Admin Reports'))
                        : reportsSubItems.filter((item) => item.label !== 'Admin Reports' && item.label !== 'My Work')
                }
            >
                <Report size={24} className="nav-item-contents" />
                <div className="nav-item-contents" data-testid="reportMenu">Reports</div>
            </LeftNavItem>

            {isSourceSystemAdminOrHigher(userProfile) && process.env.REACT_APP_CLIENT360_ENABLED === 'true' && (
                <LeftNavItem linkUrl="/clients">
                    <Collaborate size={24} className="nav-item-contents" />
                    <div className="nav-item-contents">Client 360</div>
                </LeftNavItem>
            )}

            {isSourceSystemAdminOrHigher(userProfile) && (
                <LeftNavItem
                    linkUrl="/monitoring"
                    expandable
                    subItems={
                        isGlobalAdmin(userProfile)
                            ? monitoringSubItems.filter((item) => {
                                return (
                                    item.label === 'Report Usage Metrics' || 
                                    item.label === 'Batch Runs' || 
                                    (
                                    userProfile.environmentConfig.syncToClientTrack &&
                                    (item.label === 'Canonical ID Sync' || item.label === 'Sync to ClientTrack')
                                    )
                                );
                            })
                            : monitoringSubItems.filter((item) => {
                                return (
                                    item.label === 'Batch Runs' 
                                );
                            })
                    }
                >
                    <Activity size={24} className="nav-item-contents" />
                    <div className="nav-item-contents">Monitoring</div>
                </LeftNavItem>
            )}

            {isOrgAdminOrHigher(userProfile) && (
                <LeftNavItem
                    linkUrl="/teammembers"
                    expandable={userProfile.isEccoviaUser}
                    subItems={teamMembersSubItems}
                >
                    <Events size={24} className="nav-item-contents" />
                    <div className="nav-item-contents">Team Members</div>
                </LeftNavItem>
            )}

            {isSourceSystemAdminOrHigher(userProfile) && (
                <LeftNavItem linkUrl="/datatools">
                    <Tools size={24} className="nav-item-contents" />
                    <div className="nav-item-contents">Data Tools</div>
                </LeftNavItem>
            )}

            {isSourceSystemAdminOrHigher(userProfile) && (
                <LeftNavItem linkUrl="/admin">
                    <Settings size={24} className="nav-item-contents" />
                    <div className="nav-item-contents">Configuration</div>
                </LeftNavItem>
            )}
        </Nav>
    );
};

export default LeftNav;
