import { AddFilled, Edit, Star, StarFilled, TrashCan } from '@carbon/icons-react';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import DataTable, { ColumnBreakpoints } from '../Shared/components/DataTable';
import { FavoriteReport, ReportCategory, ReportMetadata, ReportStage } from './ReportModels';
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserProfileContext, isGlobalAdmin, isOrgAdminOrHigher } from '../Shared/contexts/UserProfile';

import ApiHelper from '../Shared/ApiHelper';
import BulkDeleteModal from './BulkDeleteModal';
import BulkReassignModal from './BulkReassignModal';
import { Button } from 'react-bootstrap';
import DatasetSelectionModal from './DatasetSelectionModal';
import ReportFilters from './ReportFilters';
import { getContrastColor } from '../Shared/SharedHelpers';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export interface ReportListProps {
    endpoint: string;
    header: string;
    isPublic?: boolean;
    isDefault?: boolean;
    isAdmin?: boolean;
}

const columnBreakpoints: ColumnBreakpoints = {
    type: 'lg',
    stage: 'md',
    categories: 'xl',
    description: 'xl',
    createdBy: 'xl',
};

const ReportList: FunctionComponent<ReportListProps> = (props: ReportListProps) => {
    const { endpoint, header, isPublic, isDefault, isAdmin } = props;

    const [masterReportList, setMasterReportList] = useState<ReportMetadata[]>([]);
    const [filteredReports, setFilteredReports] = useState<ReportMetadata[]>([]);
    const [selectedReports, setSelectedReports] = useState<ReportMetadata[]>([]);

    const [reportListLoading, setReportListLoading] = useState(true);
    const [shouldDisplayAddButton, setShouldDisplayAddButton] = useState(false);
    const [showDatasetModal, setShowDatasetModal] = useState<boolean>(false);
    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<boolean>(false);
    const [showBulkReassignModal, setShowBulkReassignModal] = useState<boolean>(false);

    const refId = useRef(0);
    const userProfile = useContext(UserProfileContext);
    const hmisPublicDatasetId = userProfile.environmentConfig.pbiConfig?.datasets.find(
        (x) => x.typeName === 'HMIS_PUBLIC'
    )?.datasetGuid;

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();
    const navigate = useNavigate();
    
    const reportListColumns: ColumnDef<ReportMetadata>[] = useMemo(
        () => [
            {
                header: 'Favorite',
                accessorKey: 'isFavorited',
                cell: ({ row, getValue, column, table }: CellContext<ReportMetadata, unknown>) => {

                    return (
                        <Button
                            className="full-width"
                            variant="link"
                            onClick={(event) => {
                                event.stopPropagation();
                                const favorite: FavoriteReport = {
                                    reportId: row.original.reportId,
                                    workSpaceId: row.original.workSpaceId,
                                    reportKey: row.original.reportKey,
                                    name: row.original.name,
                                    datasetGuid: row.original.datasetGuid,
                                };

                                if (getValue() && table.options.meta?.updateData) {
                                    userProfile.removeFavoriteReport(favorite);
                                    table.options.meta.updateData(row.index, column.id, false);
                                } else if (table.options.meta?.updateData) {
                                    userProfile.addFavoriteReport(favorite);
                                    table.options.meta.updateData(row.index, column.id, true);
                                }
                            }}
                            data-testid={`favorite-button-${row.index}`}
                        >
                            {getValue() ? (
                                <StarFilled size={24} className="warning-icon-color" />
                            ) : (
                                <Star size={24} className="warning-icon-color" />
                            )}
                        </Button>
                    );
                },
                sortingFn: (rowA: Row<ReportMetadata>, rowB: Row<ReportMetadata>) => {
                    const rowAIsGreater = rowA.original.isFavorited && !rowB.original.isFavorited;

                    return rowAIsGreater ? -1 : 1;
                },
            },
            { header: 'Title', accessorKey: 'name' },
            { header: 'Type', accessorKey: 'type' },
            {
                header: 'Visibility',
                accessorKey: 'stage',
                cell: ({ row }: CellContext<ReportMetadata, unknown>) => (
                    <div>{row.original.stage !== undefined && ReportStage[row.original.stage]}</div>
                ),
            },
            {
                header: 'Categories',
                accessorKey: 'categories',
                cell: ({ row }: CellContext<ReportMetadata, unknown>) => {
                    return (
                        <div className="flex-row flex-wrap">
                            {row.original.categories?.map((category: ReportCategory) => (
                                <div
                                    key={row.id + '-' + category.categoryKey}
                                    className="category-pill mx-1 mb-1 px-2"
                                    style={{
                                        color: getContrastColor(category.colorHex),
                                        background: category.colorHex || undefined,
                                    }}
                                >
                                    {category.categoryName}
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            { header: 'Description', accessorKey: 'description' },
            {
                header: 'Owner',
                accessorKey: 'createdBy',
                cell: ({ row }: CellContext<ReportMetadata, unknown>) => (
                    <div>{row.original.ownedBy ?? row.original.createdBy}</div>
                ),
            },
        ],
        [userProfile]
    );

    useEffect(() => {
        if (reportListLoading) {
            refId.current++;
            const refSnapshot = refId.current;

            api.callApi(instance, [process.env.REACT_APP_B2C_SCOPE ?? ''], endpoint)
                .then(async (result: Response) => {
                    if (refSnapshot === refId.current) {
                        const reports = await result.json();
                        const mappedReports = reports.map((report: ReportMetadata) => {
                            return {
                                ...report,
                                isFavorited: !!userProfile.favoriteReports.find(
                                    (favorite) => favorite.reportKey === report.reportKey
                                ),
                            };
                        });

                        setMasterReportList(mappedReports);
                        setFilteredReports(mappedReports);
                        setReportListLoading(false);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching reports:', error);
                    setReportListLoading(false);
                });
        } else {
            setMasterReportList((reports) =>
                reports.map((report: ReportMetadata) => {
                    return {
                        ...report,
                        isFavorited: !!userProfile.favoriteReports.find(
                            (favorite) => favorite.reportKey === report.reportKey
                        ),
                    };
                })
            );
        }
    }, [api, endpoint, reportListLoading, instance, userProfile.favoriteReports]);

    useEffect(() => {
        setMasterReportList([]);
        setReportListLoading(true);
    }, [endpoint]);

    useEffect(() => {
        if (isAdmin) {
            setShouldDisplayAddButton(false);
        } else if (isPublic && hmisPublicDatasetId) {
            setShouldDisplayAddButton(isGlobalAdmin(userProfile));
        } else if (!isPublic) {
            setShouldDisplayAddButton(isOrgAdminOrHigher(userProfile));
        }
    }, [hmisPublicDatasetId, isPublic, isAdmin, userProfile]);

    const navigateToReport = useCallback(
        (report: ReportMetadata) => {
            navigate(`/reports/${report.workSpaceId}/${report.reportId}/${report.datasetGuid}`);
        },
        [navigate]
    );

    const refreshReportData = useCallback(() => {
        setReportListLoading(true);
        setSelectedReports([]);
    }, []);

    return (
        <div className="content-column-container">
            <div className="content-header">
                <h1 className="content-title">{header}</h1>
                <div className="flex-row">
                    {isGlobalAdmin(userProfile) && !isDefault && !isAdmin && (
                        <Button
                            data-testid="changeOwner"
                            className="mr-2"
                            variant="outline-primary"
                            disabled={!selectedReports.length}
                            onClick={() => {
                                setShowBulkReassignModal(true);
                            }}
                        >
                            <Edit size={24} className="button-icon-padding" />
                            Change Owner
                        </Button>
                    )}
                    {isGlobalAdmin(userProfile) && !isDefault && !isPublic && !isAdmin && (
                        <Button
                            data-testid="deleteReport"
                            className="mr-2"
                            variant="outline-danger"
                            disabled={!selectedReports.length}
                            onClick={() => {
                                setShowBulkDeleteModal(true);
                            }}
                        >
                            <TrashCan size={24} className="button-icon-padding" />
                            Delete Reports
                        </Button>
                    )}
                    {shouldDisplayAddButton && (
                        <Button
                            data-testid="addReport"
                            variant="outline-primary"
                            onClick={() => {
                                if (isPublic) {
                                    navigate(`/reports/public/add/${hmisPublicDatasetId}`);
                                } else {
                                    setShowDatasetModal(true);
                                }
                            }}
                        >
                            <AddFilled size={24} className="button-icon-padding" /> Add Report
                        </Button>
                    )}
                </div>
            </div>

            <ReportFilters masterReportList={masterReportList} setFilteredReports={setFilteredReports} isAdmin={isAdmin} />
            <DataTable
                label="reports"
                data={filteredReports}
                refreshData={refreshReportData}
                setData={setFilteredReports}
                columns={
                    isDefault ? reportListColumns.filter((column) => column.header !== 'Visibility') 
                    : (isAdmin ? reportListColumns.filter((column) => column.header !== 'Favorite') : (reportListColumns))
                }
                loading={reportListLoading}
                recordsAreClickable
                onRecordClick={navigateToReport}
                columnBreakpoints={columnBreakpoints}
                selectedRecords={(!isDefault && !isAdmin) ? selectedReports : undefined}
                setSelectedRecords={!isDefault ? setSelectedReports : undefined}
                recordKey="reportId"
            />

            <DatasetSelectionModal showModal={showDatasetModal} setShowModal={setShowDatasetModal} />
            <BulkDeleteModal
                showModal={showBulkDeleteModal}
                setShowModal={setShowBulkDeleteModal}
                selectedReports={selectedReports}
                refreshData={refreshReportData}
                data-testid="bulkDeleteModal"
            />
            <BulkReassignModal
                showModal={showBulkReassignModal}
                setShowModal={setShowBulkReassignModal}
                selectedReports={selectedReports}
                refreshData={refreshReportData}
                data-testid="bulkReassignModal"
            />
        </div>
    );
};

export default ReportList;
