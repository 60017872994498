import { Alert, Breadcrumb, Form } from 'react-bootstrap';
import { ArrowRight, ChevronRight, InformationFilled } from '@carbon/icons-react';
import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import Select, { Theme } from 'react-select';
import { SelectOption, SourceSystem } from '../../Shared/SharedModels';
import { Slide, toast } from 'react-toastify';
import { UserProfileContext, isGlobalAdmin } from '../../Shared/contexts/UserProfile';

import ApiHelper from '../../Shared/ApiHelper';
import { ContainerClient } from '@azure/storage-blob';
import ProjectContentFooter from '../Dedupe/ProjectSteps/ProjectContentFooter';
import ProjectContentHeader from '../Dedupe/ProjectSteps/ProjectContentHeader';
import ProjectProgressBar from '../Shared/ProjectProgressBar';
import { TransferProgressEvent } from '@azure/core-http';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

enum uploadTypes {
    HmisSourceSystem = 'HMIS',
    VashSourceSystem = 'VASH',
    HicOverwriteShell = 'CUSTOM',
}

const FileUpload: FunctionComponent = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const userProfile = useContext(UserProfileContext);

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<SourceSystem>[]>([]);
    const [selectedSourceSystemOption, setSelectedSourceSystemOption] = useState<
        SelectOption<SourceSystem> | undefined
    >(undefined);
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [preventManualUploads, setPreventManualUploads] = useState<boolean>(false);

    const [fileTypeOptions, setFileTypeOptions] = useState<SelectOption<string>[]>([]);
    const [selectedFileType, setSelectedFileType] = useState<SelectOption<string>>({
        label: 'HMIS Source System',
        value: uploadTypes.HmisSourceSystem,
    });

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [containerClient, setContainerClient] = useState<ContainerClient>();

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );

    useEffect(() => {
        const value =
            !!selectedSourceSystemOption &&
            !selectedSourceSystemOption?.value.manualUploadsEnabled &&
            selectedFileType.value !== uploadTypes.HicOverwriteShell;
        setPreventManualUploads(value);
    }, [selectedFileType.value, selectedSourceSystemOption]);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/BlobSasToken'
        ).then(async (result: Response) => {
            const url = await result.text();
            setContainerClient(new ContainerClient(url));
        });
    }, [api, instance]);

    useEffect(() => {
        const sourceSystemFolderOptions: SelectOption<SourceSystem>[] = userProfile.sourceSystems.map(
            (sourceSystem) => {
                return { label: sourceSystem.sourceSystemName, value: sourceSystem };
            }
        );

        setSourceSystemOptions(sourceSystemFolderOptions);
    }, [userProfile.sourceSystems]);

    useEffect(() => {
        if (isGlobalAdmin(userProfile)) {
            const fileTypes = [
                { label: 'HMIS Source System', value: uploadTypes.HmisSourceSystem },
            ];

            if (userProfile.environmentConfig.enableCustomUploads) {
                fileTypes.push({ label: 'VASH Source System', value: uploadTypes.VashSourceSystem });
                fileTypes.push({ label: 'HIC Overwrite / Shell', value: uploadTypes.HicOverwriteShell });
            }

            setFileTypeOptions(fileTypes);
        }
    }, [userProfile, userProfile.environmentConfig.enableCustomUploads]);

    const updateUploadProgress = useCallback(
        (progressEvent: TransferProgressEvent) => {
            if (selectedFile?.size) {
                setUploadProgress((progressEvent.loadedBytes / selectedFile.size) * 100);
            }
        },
        [selectedFile?.size]
    );

    return (
        <div className="content-column-container">
            <div className="flex-row">
                <Breadcrumb className="breadcrumb-z-index">
                    <Breadcrumb.Item
                        onClick={() => {
                            navigate('/datatools');
                        }}
                    >
                        Projects
                    </Breadcrumb.Item>
                    <ChevronRight size={24} className="breadcrumb-chevron" />
                    <Breadcrumb.Item active>Upload Files</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="project-card">
                <ProjectContentHeader header="Upload Files" />
                <Form>
                    <div className="project-card-body">
                        {isUploading ? (
                            <ProjectProgressBar
                                progress={uploadProgress}
                                header={'File Upload in Progress'}
                                progressDescription={'Please wait while we upload your file for processing'}
                                finishedDescription={'Click continue to upload more files'}
                            >
                                <img height={64} src="files.svg" alt="Data Lookup" />
                                <ArrowRight size={32} className="data-lookup-arrow" />
                                <img height={64} src="folder.svg" alt="Data Match" />
                            </ProjectProgressBar>
                        ) : (
                            <>
                                {isGlobalAdmin(userProfile) && (
                                    <div className="new-project-form">
                                        <Form.Group className="project-form-group">
                                            <Form.Label>File Type</Form.Label>

                                            <Select
                                                options={fileTypeOptions}
                                                value={selectedFileType}
                                                theme={formSelectTheme}
                                                placeholder="Select File Type..."
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        setSelectedFileType(selectedOption);
                                                    }
                                                }}
                                                menuPlacement="auto"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                File Type is required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                )}

                                <div className="new-project-form">
                                    <Form.Group className="project-form-group">
                                        <Form.Label>Source System</Form.Label>

                                        <Select
                                            options={sourceSystemOptions}
                                            value={selectedSourceSystemOption}
                                            theme={formSelectTheme}
                                            placeholder="Select Source System..."
                                            isLoading={sourceSystemOptions.length <= 0}
                                            isDisabled={selectedFileType.value === uploadTypes.HicOverwriteShell}
                                            onChange={(selectedOption) => {
                                                setSelectedSourceSystemOption(selectedOption ?? undefined);
                                            }}
                                            menuPlacement="auto"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Source System is required
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="project-form-group">
                                        <Form.Label>File to Upload</Form.Label>
                                        <Form.Control
                                            type="file"
                                            accept=".zip"
                                            isInvalid={selectedFile != null && selectedFile.name.slice(-4) !== '.zip'}
                                            disabled={preventManualUploads}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setSelectedFile(e.target.files?.item(0));
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            File to Upload must be a .zip file
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                {preventManualUploads && (
                                    <Alert variant="warning">
                                        <InformationFilled size={20} className="mr-2" />
                                        <span className="alert-warning-text">
                                            Manual Uploads for {selectedSourceSystemOption?.value.sourceSystemName} have
                                            been disabled. Please initiate uploads directly from your source system.
                                        </span>
                                    </Alert>
                                )}
                            </>
                        )}
                    </div>
                    <ProjectContentFooter
                        disableContinue={
                            !selectedFile ||
                            (!selectedSourceSystemOption && selectedFileType.value !== uploadTypes.HicOverwriteShell) ||
                            selectedFile.name.slice(-4) !== '.zip' ||
                            isUploading ||
                            preventManualUploads
                        }
                        continueCallback={async () => {
                            if (
                                containerClient &&
                                selectedFile?.name &&
                                (selectedSourceSystemOption?.value ||
                                    selectedFileType.value === uploadTypes.HicOverwriteShell)
                            ) {
                                try {
                                    setIsUploading(true);
                                    let fileName = selectedFile.name;

                                    switch (selectedFileType.value) {
                                        case uploadTypes.HicOverwriteShell:
                                            fileName = `CUSTOM/${selectedFile.name}`;
                                            break;
                                        case uploadTypes.HmisSourceSystem:
                                            fileName = `${
                                                selectedSourceSystemOption?.value.sourceSystemShortName + '/'
                                            }${selectedFile.name}`;
                                            break;
                                        case uploadTypes.VashSourceSystem:
                                            fileName = `${
                                                selectedSourceSystemOption?.value.sourceSystemShortName + '/'
                                            }VASH/${selectedFile.name}`;
                                            break;
                                        default:
                                            break;
                                    }

                                    const blockBlobClient = containerClient.getBlockBlobClient(fileName);

                                    await blockBlobClient.uploadData(selectedFile, {
                                        onProgress: updateUploadProgress,
                                    });

                                    // log upload data via usage logging api
                                    const uploadEvent = JSON.stringify({
                                        fileName: selectedFile?.name,
                                        sourceSystemName: selectedSourceSystemOption?.value.sourceSystemShortName,
                                        activity: 'FileUpload',
                                    });
                                    await api.callApi(
                                        instance,
                                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                        process.env.REACT_APP_NET_API_URL + '/UsageLogging/fileupload',
                                        'POST',
                                        uploadEvent
                                    );

                                    navigate('/datatools/upload/success');
                                } catch (error) {
                                    toast.error(
                                        'Upload failed, refresh and try again, and contact support if the error persists',
                                        {
                                            position: 'top-right',
                                            autoClose: false,
                                            transition: Slide,
                                            draggable: false,
                                            closeOnClick: false,
                                            theme: 'colored',
                                        }
                                    );
                                }
                            }
                        }}
                    />
                </Form>
            </div>
        </div>
    );
};

export default FileUpload;
