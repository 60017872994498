import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ReportCategory, ReportMetadata } from './ReportModels';

import FilterResetButton from '../Shared/components/FilterResetButton';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { SelectOption } from '../Shared/SharedModels';
import { bool } from 'yup';

interface ReportFiltersProps {
    masterReportList: ReportMetadata[];
    setFilteredReports: (reports: ReportMetadata[]) => void;
    isAdmin?: boolean;
}

const ReportFilters: FunctionComponent<ReportFiltersProps> = (props: ReportFiltersProps) => {
    const { masterReportList, setFilteredReports: setReports, isAdmin } = props;

    const [reportName, setReportName] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [favoriteStatus, setFavoriteStatus] = useState<SelectOption<boolean>[]>([]);
    const [favoriteStatusOptions] = useState<SelectOption<boolean>[]>([
        { label: 'Favorites', value: true },
        { label: 'Others', value: false },
    ]);
    const [types, setTypes] = useState<SelectOption<string>[]>([]);
    const [typeOptions] = useState<SelectOption<string>[]>([
        { label: 'Standard', value: 'Standard' },
        { label: 'Custom', value: 'Custom' },
    ]);
    const [categories, setCategories] = useState<SelectOption<string>[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOption<string>[]>([]);
    const [areFiltersApplied, setAreFiltersApplied] = useState(false);

    const clearFilters = useCallback(() => {
        setReportName('');
        setCreatedBy('');
        setFavoriteStatus([]);
        setTypes([]);
        setCategories([]);
    }, []);

    useEffect(() => {
        setAreFiltersApplied(
            !!reportName || !!createdBy || !!favoriteStatus.length || !!types.length || !!categories.length
        );
    }, [reportName, createdBy, favoriteStatus.length, types.length, categories.length]);

    useEffect(() => {
        let presentCategories: ReportCategory[] = [];
        let categoryNames: string[] = [];

        masterReportList.forEach((report) => {
            if (report.categories) {
                presentCategories = presentCategories.concat(report.categories);
            }
        });

        categoryNames = presentCategories.map((category) => category.categoryName);
        categoryNames = [...new Set(categoryNames)];

        setCategoryOptions(
            categoryNames.map((category) => {
                return { label: category, value: category };
            })
        );
    }, [masterReportList]);

    useEffect(() => {
        let filteredReports = masterReportList;

        if (reportName) {
            filteredReports = filteredReports.filter((report) => {
                return report.name?.toLowerCase().includes(reportName.toLowerCase());
            });
        }

        if (createdBy) {
            filteredReports = filteredReports.filter((report) => {
                return report.createdBy?.toLowerCase().includes(createdBy.toLowerCase());
            });
        }

        if (favoriteStatus.length) {
            filteredReports = filteredReports.filter((report) => {
                return favoriteStatus.find((status) => {
                    return status.value === report.isFavorited;
                });
            });
        }

        if (types.length) {
            filteredReports = filteredReports.filter((report) => {
                return types.find((reportType) => {
                    return reportType.value === report.type;
                });
            });
        }

        if (categories.length) {
            filteredReports = filteredReports.filter((report) => {
                const categoryMatches = report.categories?.filter((reportCategory) =>
                    categories.find((filterValue) => {
                        return filterValue.value === reportCategory.categoryName;
                    })
                );

                return categoryMatches?.length;
            });
        }

        setReports(filteredReports);
    }, [categories, createdBy, favoriteStatus, masterReportList, reportName, setReports, types]);

    return (
        <>
            <FilterResetButton clickCallback={clearFilters} filtersApplied={areFiltersApplied} />

            <Form className="filterFormContainer">
                <Form.Group className="data-table-filter">
                    <Form.Label>Favorites</Form.Label>
                    <Select
                        isMulti
                        className="filterSelect"
                        closeMenuOnSelect={false}
                        placeholder="Enter Status..."
                        value={favoriteStatus}
                        options={favoriteStatusOptions}
                        onChange={(selectedOptions) => {
                            setFavoriteStatus(selectedOptions as SelectOption<boolean>[]);
                        }}
                        menuPlacement="auto"
                        id="favorites-select"
                        data-testid="favoriteStatusFilter"
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Report Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Report Name..."
                        value={reportName}
                        onChange={(event) => {
                            setReportName(event.target.value);
                        }}
                        id="report-name-input"
                        data-testid="reportNameFilter"
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Type</Form.Label>
                    <Select
                        isMulti
                        className="filterSelect"
                        closeMenuOnSelect={false}
                        placeholder="Enter Type..."
                        value={types}
                        options={typeOptions.filter(r => isAdmin && r.label !== 'Standard' && r.label !=='Custom')}
                        onChange={(selectedOptions) => {
                            setTypes(selectedOptions as SelectOption<string>[]);
                        }}
                        menuPlacement="auto"
                        id="type-select"
                        data-testid="typeFilter"
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Category</Form.Label>
                    <Select
                        isMulti
                        className="filterSelect"
                        closeMenuOnSelect={false}
                        placeholder="Enter Category..."
                        value={categories}
                        options={categoryOptions}
                        onChange={(selectedOptions) => {
                            setCategories(selectedOptions as SelectOption<string>[]);
                        }}
                        menuPlacement="auto"
                        id="category-select"
                        data-testid="categoryFilter"
                    />
                </Form.Group>

                <Form.Group className="data-table-filter">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Creator..."
                        value={createdBy}
                        onChange={(event) => {
                            setCreatedBy(event.target.value);
                        }}
                        id="created-by-input"
                        data-testid="createdByFilter"
                    />
                </Form.Group>
            </Form>
        </>
    );
};

export default ReportFilters;
