import { Button, Form, Spinner } from 'react-bootstrap';
import { DisplayUser, User, UserStatus } from './TeamMemberModels';
import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';

import ApiHelper from '../Shared/ApiHelper';
import { Close } from '@carbon/icons-react';
import DeleteUserButton from './UserForm/DeleteUserButton';
import { FormProvider } from '../Shared/contexts/FormProvider';
import ResendInvite from './UserForm/ResendInvite';
import UserForm from './UserForm/UserForm';
import { UserRoles } from '../Shared/SharedModels';
import { useMsal } from '@azure/msal-react';
import useUserForm from './UserForm/useUserForm';

interface EditUserPanelProps {
    user: User;
    setUserWasSaved: Dispatch<SetStateAction<boolean>>;
    showPanel: boolean;
    setShowPanel: Dispatch<SetStateAction<boolean>>;
    isEccoviaUser?: boolean;
    hasEditPermissions: boolean;
    setUserList: Dispatch<SetStateAction<DisplayUser[]>>;
    userList: DisplayUser[];
}

const EditUserPanel: FunctionComponent<EditUserPanelProps> = (props: EditUserPanelProps) => {
    const { user, setUserWasSaved, showPanel, setShowPanel, isEccoviaUser, hasEditPermissions, setUserList, userList } =
        props;

    const [formIsDirty, setFormIsDirty] = useState(false);
    const [prevWorkingUserId, setPrevWorkingUserId] = useState<string | undefined>();
    const [savingChangesInProgress, setSavingChangesInProgress] = useState<boolean>(false);

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    const methods = useUserForm({ isEccoviaUser, user });
    const { formState } = methods;

    const handleSubmit = methods.handleSubmit((user) => {
        setFormIsDirty(false);
        setSavingChangesInProgress(true);

        const userPayload = user;
        if (userPayload.role?.roleKey === UserRoles['Project User']) {
            userPayload.organizations = [];
        }

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + `/User${isEccoviaUser ? '/eccovia' : ''}`,
            'PUT',
            JSON.stringify(userPayload)
        ).then(async (data: Response) => {
            const result: User = (await data.json()) as User;
            const newUserList: DisplayUser[] = userList.map((user) => {
                if (user.fullUser.upn == result.upn) {
                    return {
                        name: result.firstName + ' ' + result.lastName,
                        role: result.role?.name,
                        jobTitle: result.jobTitle,
                        status: result.status !== undefined ? UserStatus[result.status] : undefined,
                        fullUser: result,
                    };
                }
                return user;
            });
            setUserList(newUserList);
            setUserWasSaved(true);
            setSavingChangesInProgress(false);
        });
    });

    useEffect(() => {
        if (prevWorkingUserId === formState?.userKey) {
            setFormIsDirty(true);
        } else {
            setPrevWorkingUserId(formState?.userKey);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleClosePanel = () => {
        setFormIsDirty(false);
        setShowPanel(false);
    };

    const isSaveDisabled = !formIsDirty || savingChangesInProgress;

    return showPanel ? (
        <div className="edit-user-panel">
            <div className="content-header edit-panel-header" data-testid="editPanelHeader">
                <h5>Profile</h5>

                <Button
                    variant="link"
                    onClick={handleClosePanel}
                    data-testid="closeButton"
                >
                    Close <Close size={24} />
                </Button>
            </div>

            <FormProvider {...methods}>
                {formState && (
                    <Form onSubmit={handleSubmit}>
                        <fieldset disabled={!hasEditPermissions}>
                            <UserForm
                                mode={'Edit'}
                                isEccoviaUser={!!isEccoviaUser}
                                editEnabledForAdmin={hasEditPermissions}
                            />
                        </fieldset>
                        <div className="edit-panel-footer" data-testid="editPanelFooter">
                            {hasEditPermissions && user.status === UserStatus.Pending && !!user.upn && !isEccoviaUser && (
                                <DeleteUserButton
                                    user={user}
                                    setFormIsDirty={setFormIsDirty}
                                    setShowPanel={setShowPanel}
                                    setUserWasSaved={setUserWasSaved}
                                    data-testid="deleteUserButton"
                                />
                            )}

                            {hasEditPermissions && user.status === UserStatus.Pending && !!user.upn && (
                                <ResendInvite email={user.upn} isEccoviaUser={isEccoviaUser} data-testid="resendInviteButton" />
                            )}

                            {hasEditPermissions && (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSaveDisabled}
                                    data-testid="saveChangesButton"
                                >
                                    {savingChangesInProgress ? (
                                        <>
                                            <Spinner
                                                size="sm"
                                                className="button-icon-padding button-spinner"
                                                animation="border"
                                                data-testid="savingSpinner"
                                            />
                                            Saving
                                        </>
                                    ) : (
                                        <>Save Changes</>
                                    )}
                                </Button>
                            )}
                        </div>
                    </Form>
                )}
            </FormProvider>
        </div>
    ) : null;
};

export default EditUserPanel;
