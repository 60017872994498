import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import React, { Dispatch, SetStateAction } from 'react';

import { Batch } from './BatchRunModels';
import { Breadcrumb } from 'react-bootstrap';
import styles from './BatchRunBreadCrumb.module.css';

interface BatchRunBreadCrumbProps {
    setSelectedSourceSystemKey: Dispatch<SetStateAction<number | null>>;
    setSelectedBatchRun: Dispatch<SetStateAction<Batch | undefined>>;
    sourceSystemName?: string;
    batchName?: string;
}

const BatchRunBreadCrumb: React.FC<BatchRunBreadCrumbProps> = ({
    setSelectedSourceSystemKey,
    setSelectedBatchRun,
    sourceSystemName,
    batchName,
}) => {
    return (
        <div className={`d-flex flex-row ${styles.breadcrumbContainer}`}>
            {!!sourceSystemName && (
                <button
                    className={styles.backButton}
                    onClick={() => {
                        if (batchName) {
                            setSelectedBatchRun(undefined);
                        } else if (sourceSystemName) {
                            setSelectedSourceSystemKey(null);
                        }
                    }}
                    aria-label="Go back"
                >
                    <ChevronLeft size={24} />
                    <span className="ms-2">Back</span>
                </button>
            )}
            <Breadcrumb className="ms-3">
                <Breadcrumb.Item
                    onClick={() => {
                        setSelectedBatchRun(undefined);
                        setSelectedSourceSystemKey(null);
                    }}
                    className={styles.allBatches}
                >
                    All Batches
                </Breadcrumb.Item>
                {!!sourceSystemName && (
                    <>
                        <ChevronRight size={24} className="breadcrumb-chevron" />
                        <Breadcrumb.Item
                            active={!!sourceSystemName && !batchName}
                            onClick={() => {
                                setSelectedBatchRun(undefined);
                            }}
                        >
                            {sourceSystemName}
                        </Breadcrumb.Item>
                    </>
                )}
                {!!batchName && (
                    <>
                        <ChevronRight size={24} className="breadcrumb-chevron" />
                        <Breadcrumb.Item active>
                            {batchName}
                        </Breadcrumb.Item>
                    </>
                )}
            </Breadcrumb>
        </div>
    );
};

export default BatchRunBreadCrumb;
