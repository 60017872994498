import { Button, Form, Spinner } from 'react-bootstrap';
import { CustomerInventoryCsv, VCOInventory, VCOProject } from './CustomProjectModels';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Slide, toast } from 'react-toastify';

import { AddFilled } from '@carbon/icons-react';
import ApiHelper from '../../../Shared/ApiHelper';
import DatePicker from 'react-datepicker';
import DeleteButton from '../../../Shared/components/DeleteButton';
import SaveButton from '../../../Shared/components/SaveButton';
import Select from 'react-select';
import { SelectOption } from '../../../Shared/SharedModels';
import { UserProfileContext } from '../../../Shared/contexts/UserProfile';
import { useMsal } from '@azure/msal-react';

export interface InventoryEditFormProps {
    vcoProject: VCOProject;
    batchKey: number;
}

function getInventoryOptionLabel(csv: CustomerInventoryCsv): string {
    if (!csv.inventoryID) return 'New Inventory';

    return `Household Type: ${csv.householdType} - ${csv.inventoryEndDate ? 'Inactive' : 'Active'} - ${
        csv.inventoryID
    }`;
}

const InventoryEditForm: FunctionComponent<InventoryEditFormProps> = (props: InventoryEditFormProps) => {
    const { vcoProject, batchKey } = props;
    const userProfile = useContext(UserProfileContext);

    const defaultInventoryCsv = useMemo<CustomerInventoryCsv>(() => {
        return { batchKey: 0, sourceSystemShortName: '', projectID: '' };
    }, []);

    const [selectedInventory, setSelectedInventory] = useState<CustomerInventoryCsv | undefined>();
    const [inventoryOptions, setInventoryOptions] = useState<SelectOption<CustomerInventoryCsv>[]>([]);

    const [isSaving, setIsSaving] = useState(false);
    const [canSave, setCanSave] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const api = useMemo(() => new ApiHelper(), []);
    const { instance } = useMsal();

    useEffect(() => {
        setCanSave(
            !!selectedInventory?.coCCode &&
                !!selectedInventory.unitInventory &&
                !!selectedInventory.bedInventory &&
                !!selectedInventory.inventoryStartDate
        );
    }, [
        selectedInventory?.bedInventory,
        selectedInventory?.coCCode,
        selectedInventory?.inventoryStartDate,
        selectedInventory?.unitInventory,
    ]);

    useEffect(() => {
        if (isLoading && vcoProject.projectKey) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/CustomProject/projects/${vcoProject.projectKey}/inventory`,
                'GET'
            )
                .then(async (result: Response) => {
                    const inventories: VCOInventory[] = await result.json();

                    const customInventories: CustomerInventoryCsv[] = inventories.map<CustomerInventoryCsv>((vco) => {
                        const csv: CustomerInventoryCsv = {
                            batchKey: batchKey,
                            inventoryID: vco.inventoryID,
                            sourceSystemShortName:
                                userProfile.sourceSystems.find((s) => s.sourceSystemKey === vcoProject.sourceSystemKey)
                                    ?.sourceSystemShortName ?? '',
                            projectID: vco.projectID ?? '',
                            coCCode: vco.coCCode,
                            householdType: vco.householdTypeKey,
                            availability: vco.availabilityKey,
                            unitInventory: vco.unitInventory,
                            bedInventory: vco.bedInventory,
                            cHVetBedInventory: vco.chVetBedInventory,
                            youthVetBedInventory: vco.youthVetBedInventory,
                            vetBedInventory: vco.vetBedInventory,
                            cHYouthBedInventory: vco.chYouthBedInventory,
                            youthBedInventory: vco.youthBedInventory,
                            cHBedInventory: vco.chBedInventory,
                            otherBedInventory: vco.otherBedInventory,
                            eSBedType: vco.esBedTypeKey,
                            inventoryStartDate: vco.inventoryStartDate,
                            inventoryEndDate: vco.inventoryEndDate,
                            dateCreated: vco.dateCreated,
                            dateUpdated: vco.dateUpdated,
                            userID: vco.userID,
                            dateDeleted: vco.dateDeleted,
                        };

                        return csv;
                    });

                    if (customInventories.length) {
                        setInventoryOptions(
                            customInventories
                                .map((csv) => {
                                    const option: SelectOption<CustomerInventoryCsv> = {
                                        label: getInventoryOptionLabel(csv),
                                        value: csv,
                                    };
                                    return option;
                                })
                                .sort((a, b) => {
                                    if (a.value.inventoryEndDate && b.value.inventoryEndDate) {
                                        return 0;
                                    } else if (a.value.inventoryEndDate) {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                })
                        );
                        setSelectedInventory(customInventories[0]);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [
        api,
        batchKey,
        instance,
        isLoading,
        userProfile.sourceSystems,
        vcoProject.projectKey,
        vcoProject.sourceSystemKey,
    ]);

    return (
        <div className="project-card mb-5">
            <div className="project-card-header">
                <h3>Inventory CSV Data</h3>
                {!!batchKey && !isLoading && (
                    <div className="flex-row">
                        {selectedInventory?.inventoryID && (
                            <DeleteButton
                                isLoading={isSaving}
                                isDisabled={!canSave}
                                onClick={() => {
                                    setIsSaving(true);

                                    const dateDeleted = new Date().toISOString();
                                    const payload: CustomerInventoryCsv = {
                                        ...selectedInventory,
                                        dateDeleted: dateDeleted,
                                        dateUpdated: dateDeleted,
                                        inventoryStartDate: selectedInventory.inventoryStartDate?.split('T')[0],
                                        inventoryEndDate: selectedInventory.inventoryEndDate?.split('T')[0],
                                    };

                                    api.callApi(
                                        instance,
                                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                        process.env.REACT_APP_NET_API_URL + `/CustomProject/inventory`,
                                        'POST',
                                        JSON.stringify(payload)
                                    )
                                        .then(async (_result: Response) => {
                                            toast.success('Inventory Data Deleted Successfully', {
                                                position: 'top-right',
                                                autoClose: 5000,
                                                transition: Slide,
                                                draggable: false,
                                                closeOnClick: false,
                                                theme: 'colored',
                                            });

                                            setIsLoading(true);
                                        })
                                        .finally(() => {
                                            setIsSaving(false);
                                        });
                                }}
                            />
                        )}
                        <Button
                            className="form-action-button mr-2 ml-2"
                            variant="outline-primary"
                            disabled={isSaving}
                            onClick={() => {
                                const currentDate = new Date().toISOString().split('T')[0];
                                const newInventory: CustomerInventoryCsv = {
                                    batchKey: batchKey,
                                    sourceSystemShortName:
                                        userProfile.sourceSystems.find(
                                            (s) => s.sourceSystemKey === vcoProject.sourceSystemKey
                                        )?.sourceSystemShortName ?? '',
                                    projectID: vcoProject.projectId ?? '',
                                    dateCreated: currentDate,
                                    dateUpdated: currentDate,
                                    userID: vcoProject.userID,
                                };

                                setSelectedInventory(newInventory);
                            }}
                        >
                            <AddFilled size={24} className="button-icon-padding" /> Add Inventory
                        </Button>
                        {selectedInventory && (
                            <SaveButton
                                isSaving={isSaving}
                                isDisabled={!canSave}
                                onClick={() => {
                                    setIsSaving(true);

                                    const payload: CustomerInventoryCsv = {
                                        ...selectedInventory,
                                        inventoryStartDate: selectedInventory.inventoryStartDate?.split('T')[0],
                                        inventoryEndDate: selectedInventory.inventoryEndDate?.split('T')[0],
                                    };

                                    api.callApi(
                                        instance,
                                        [process.env.REACT_APP_B2C_SCOPE ?? ''],
                                        process.env.REACT_APP_NET_API_URL + `/CustomProject/inventory`,
                                        'POST',
                                        JSON.stringify(payload)
                                    )
                                        .then(async (_result: Response) => {
                                            toast.success('Inventory Data Saved Successfully', {
                                                position: 'top-right',
                                                autoClose: 5000,
                                                transition: Slide,
                                                draggable: false,
                                                closeOnClick: false,
                                                theme: 'colored',
                                            });

                                            setIsLoading(true);
                                        })
                                        .finally(() => {
                                            setIsSaving(false);
                                        });
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            {isLoading ? (
                <Spinner
                    className="centered-spinner"
                    variant="primary"
                    size="sm"
                    animation="border"
                    data-testid="inventoryEditFormSpinner"
                />
            ) : (
                <div className="project-card-body">
                    {inventoryOptions.length || selectedInventory ? (
                        <>
                            <Form.Group className="wide-card-form-group">
                                <Form.Label>Inventory ID</Form.Label>
                                <Select
                                    isClearable
                                    isLoading={isLoading}
                                    isDisabled={isSaving}
                                    className="filterSelect"
                                    placeholder="Select Inventory..."
                                    value={
                                        selectedInventory
                                            ? {
                                                  label: getInventoryOptionLabel(selectedInventory),
                                                  value: selectedInventory,
                                              }
                                            : undefined
                                    }
                                    options={inventoryOptions}
                                    onChange={(selectedOption) => {
                                        setSelectedInventory(selectedOption?.value);
                                    }}
                                />
                            </Form.Group>
                            <Form className="card-form">
                                {selectedInventory && (
                                    <>
                                        <Form.Group className="card-form-group">
                                            <Form.Label>Household Type</Form.Label>
                                            <Form.Control
                                                data-testid="householdTypeInput"
                                                placeholder="Enter Household Type*"
                                                isInvalid={!selectedInventory.householdType}
                                                value={selectedInventory.householdType ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                householdType: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                householdType: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Availability</Form.Label>
                                            <Form.Control
                                                data-testid="availabilityInput"
                                                placeholder="Enter Availability"
                                                value={selectedInventory.availability ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                availability: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                availability: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Unit Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="unitInventoryInput"
                                                placeholder="Enter Unit Inventory*"
                                                value={selectedInventory.unitInventory ?? ''}
                                                isInvalid={!selectedInventory.unitInventory}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                unitInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                unitInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="bedInventoryInput"
                                                placeholder="Enter Bed Inventory*"
                                                value={selectedInventory.bedInventory ?? ''}
                                                isInvalid={!selectedInventory.bedInventory}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                bedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                bedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>CH Vet Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="chVetBedInventoryInput"
                                                placeholder="Enter CH Vet Bed Inventory"
                                                value={selectedInventory.cHVetBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHVetBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHVetBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Youth Vet Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="youthVetBedInventoryInput"
                                                placeholder="Enter Youth Vet Bed Inventory"
                                                value={selectedInventory.youthVetBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                youthVetBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                youthVetBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Vet Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="vetBedInventoryInput"
                                                placeholder="Enter Vet Bed Inventory"
                                                value={selectedInventory.vetBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                vetBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                vetBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>CH Youth Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="chYouthBedInventoryInput"
                                                placeholder="Enter CH Youth Bed Inventory"
                                                value={selectedInventory.cHYouthBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHYouthBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHYouthBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Youth Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="youthBedInventoryInput"
                                                placeholder="Enter Youth Bed Inventory"
                                                value={selectedInventory.youthBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                youthBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                youthBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>CH Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="chBedInventoryInput"
                                                placeholder="Enter CH Bed Inventory"
                                                value={selectedInventory.cHBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                cHBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Other Bed Inventory</Form.Label>
                                            <Form.Control
                                                data-testid="otherBedInventoryInput"
                                                placeholder="Enter Other Bed Inventory"
                                                value={selectedInventory.otherBedInventory ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                otherBedInventory: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                otherBedInventory: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>ES Bed type</Form.Label>
                                            <Form.Control
                                                data-testid="esBedTypeInput"
                                                placeholder="Enter ES Bed Type"
                                                value={selectedInventory.eSBedType ?? ''}
                                                onChange={(event) => {
                                                    const value = parseInt(event.target.value);
                                                    const isNumber = !isNaN(value);
                                                    if (isNumber) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                eSBedType: value,
                                                            };
                                                        });
                                                    } else if (event.target.value === '') {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                eSBedType: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Inventory Start Date*</Form.Label>
                                            <DatePicker
                                                placeholderText="Inventory Start Date"
                                                className="form-control"
                                                dateFormat="MM-dd-yyyy"
                                                selected={
                                                    selectedInventory.inventoryStartDate
                                                        ? new Date(selectedInventory.inventoryStartDate)
                                                        : undefined
                                                }
                                                onChange={(date: Date | null) => {
                                                    setSelectedInventory((prev) => {
                                                        return {
                                                            ...defaultInventoryCsv,
                                                            ...prev,
                                                            inventoryStartDate: date ? date.toISOString() : undefined,
                                                        };
                                                    });
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>Inventory End Date</Form.Label>
                                            <DatePicker
                                                placeholderText="Inventory End Date"
                                                className="form-control"
                                                dateFormat="MM-dd-yyyy"
                                                selected={
                                                    selectedInventory.inventoryEndDate
                                                        ? new Date(selectedInventory.inventoryEndDate)
                                                        : undefined
                                                }
                                                onChange={(date: Date | null) => {
                                                    setSelectedInventory((prev) => {
                                                        return {
                                                            ...defaultInventoryCsv,
                                                            ...prev,
                                                            inventoryEndDate: date ? date.toISOString() : undefined,
                                                        };
                                                    });
                                                }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="card-form-group">
                                            <Form.Label>CoC Code*</Form.Label>
                                            <Form.Control
                                                data-testid="cocCodeInput"
                                                placeholder="Enter CoC Code"
                                                value={selectedInventory.coCCode ?? ''}
                                                isInvalid={!selectedInventory.coCCode}
                                                onChange={(event) => {
                                                    if (event.target.value && event.target.value.length <= 6) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                coCCode: event.target.value,
                                                            };
                                                        });
                                                    } else if (!event.target.value) {
                                                        setSelectedInventory((prev) => {
                                                            return {
                                                                ...defaultInventoryCsv,
                                                                ...prev,
                                                                coCCode: undefined,
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </>
                                )}
                            </Form>
                        </>
                    ) : (
                        <p>No inventory records found for project</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default InventoryEditForm;
