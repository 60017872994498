import './BatchRuns.css';

import { Batch, BatchStatus, SourceSystemRegister } from './BatchRunModels';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { Theme } from 'react-select';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import ApiHelper from '../../Shared/ApiHelper';
import BatchDetails from './BatchDetails';
import BatchRunsStages from './BatchRunsStages';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from '../../Shared/components/DataTable';
import DatePicker from '../../Shared/components/DatePicker';
import { Form } from 'react-bootstrap';
import { SelectOption } from '../../Shared/SharedModels';
import SourceSystemDetails from './SourceSystemDetails';
import { formatDateTimeISO } from '../../Shared/SharedHelpers';
import { useMsal } from '@azure/msal-react';

const BatchRuns: React.FC = () => {
    const { instance } = useMsal();
    const api = useMemo(() => new ApiHelper(), []);

    

    const [sourceSystemRegisters, setSourceSystemRegisters] = useState<SourceSystemRegister[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [sourceSystemOptions, setSourceSystemOptions] = useState<SelectOption<number>[]>([]);
    const [sourceSystemFilterValue, setSourceSystemFilterValue] = useState<SelectOption<number>[]>([]);
    const [selectedBatchStatus, setSelectedBatchStatus] = useState<BatchStatus | null>(null);
    const [selectedStageId, setSelectedStageId] = useState<number | null>(null);
    const [selectedSourceSystemKey, setSelectedSourceSystemKey] = useState<number | null>(null);
    const [selectedBatchRun, setSelectedBatchRun] = useState<Batch>();

    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState<Date | null>(null);

    const formSelectTheme = useCallback(
        (theme: Theme) => ({
            ...theme,
            borderRadius: 8,
        }),
        []
    );

    const fetchBatches = useCallback(async () => {
        if (!startDate || selectedStageId == null) {
            return;
        }

        try {
            setIsLoading(true);
            setSourceSystemRegisters([]);
            setSelectedSourceSystemKey(null);

            const payload = JSON.stringify({
                startDate: formatDateTimeISO(startOfDay(startDate)),
                endDate: endDate && formatDateTimeISO(endOfDay(endDate)),
            });

            const response: Response = await api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                `${process.env.REACT_APP_NET_API_URL}/batch/${selectedStageId}`,
                'POST',
                payload
            );
            const registersResponse: SourceSystemRegister[] = await response.json();
            setSourceSystemRegisters(registersResponse || []);
            if (registersResponse.length === 1) {
                setSelectedSourceSystemKey(registersResponse[0].sourceSystem.sourceSystemKey);
            }
        } finally {
            setIsLoading(false);
        }
    }, [startDate, selectedStageId, endDate, api, instance]);

    const filteredRegisters = useMemo(() => {
        let filteredRegisters = sourceSystemRegisters;

        if (selectedBatchStatus) {
            const resultStatus = selectedBatchStatus === BatchStatus.PROCESSED;
            filteredRegisters = filteredRegisters.map((register) => ({
                ...register,
                batches: register.batches.filter((batch) => batch.resultStatus === resultStatus),
            }));
        }

        if (sourceSystemFilterValue.length > 0) {
            filteredRegisters = filteredRegisters.filter(
                (register) =>
                    sourceSystemFilterValue.find(
                        (filterValue) => filterValue.value === register.sourceSystem.sourceSystemKey
                    ) !== undefined
            );
        }

        return filteredRegisters;
    }, [selectedBatchStatus, sourceSystemRegisters, sourceSystemFilterValue]);

    const selectedSourceSystemRegister = useMemo(() => {
        return filteredRegisters.find(
            (register) => register.sourceSystem.sourceSystemKey === selectedSourceSystemKey
        );
    }, [filteredRegisters, selectedSourceSystemKey]);

    useEffect(() => {
        fetchBatches();
    }, [fetchBatches]);

    useEffect(() => {
        const sourceSystems: SelectOption<number>[] = sourceSystemRegisters.map((register) => {
            const sourceSystemOption: SelectOption<number> = {
                label: register.sourceSystem.sourceSystemShortName,
                value: register.sourceSystem.sourceSystemKey,
            };
            return sourceSystemOption;
        });
        setSourceSystemOptions(sourceSystems);
    }, [sourceSystemRegisters]);

    return (
        <div className="batch-runs__container">
            <div className="content-header" data-testid="batchRunsHeader">
                <h1>Batch Runs</h1>
            </div>
            <p className="data-tools-description mb-4">
                This page is to monitor the processing of your data, click the arrows to view details about specific
                stages
            </p>

            <BatchRunsStages
                selectedStage={selectedStageId}
                onSelectedStageChange={setSelectedStageId}
                startDate={startDate}
                endDate={endDate}
                selectedStatus={selectedBatchStatus}
                onSelectedStatusChange={(value) => {
                    setSelectedBatchRun(undefined);
                    setSelectedSourceSystemKey(null);
                    setSelectedBatchStatus(value);
                }}
            />

            {selectedStageId != null && (
                <>
                    <div className="batch-runs__highlight">
                        <div className="">
                            <Form className="batch-runs__filters">
                                <Form.Group className="data-table-filter" data-testid="startDate-Filter">
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker
                                        value={startDate}
                                        isClearVisible={false}
                                        onChange={(date: Date | null) => date && setStartDate(date)}
                                    />
                                </Form.Group>
                                <Form.Group className="data-table-filter" data-testid="endDate-Filter">
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker
                                        value={endDate}
                                        onChange={setEndDate}
                                    />
                                </Form.Group>
                                {!selectedSourceSystemKey && (
                                    <Form.Group className="data-table-filter" data-testid="sourceSystem-Filter">
                                        <Form.Label>Source System</Form.Label>
                                        <Select
                                            isMulti
                                            className="filteredSelect"
                                            placeholder="Select Source System..."
                                            value={sourceSystemFilterValue}
                                            theme={formSelectTheme}
                                            options={sourceSystemOptions}
                                            onChange={(selectedOptions) => {
                                                setSourceSystemFilterValue(selectedOptions as SelectOption<number>[]);
                                            }}
                                            menuPlacement="auto"
                                        />
                                    </Form.Group>
                                )}
                            </Form>
                        </div>
                    </div>

                    <div key={selectedSourceSystemKey ?? 'default'} className="batch-runs__content">
                        {!selectedSourceSystemRegister ? (
                            <DataTable
                                label="source systems"
                                hasTopRadius={false}
                                data={filteredRegisters}
                                columns={sourceSystemColumns}
                                loading={isLoading}
                                recordsAreClickable
                                onRecordClick={({ sourceSystem }) =>
                                    setSelectedSourceSystemKey(sourceSystem.sourceSystemKey)
                                }
                            />
                        ) : !selectedBatchRun ? (
                            <SourceSystemDetails
                                batches={selectedSourceSystemRegister.batches}
                                sourceSystem={selectedSourceSystemRegister.sourceSystem}
                                setSelectedSourceSystemKey={setSelectedSourceSystemKey}
                                setSelectedBatchRun={setSelectedBatchRun}
                            />
                        ) : (
                            <BatchDetails
                                batch={selectedBatchRun}
                                setSelectedSourceSystemKey={setSelectedSourceSystemKey}
                                setSelectedBatchRun={setSelectedBatchRun}
                                sourceSystemName={selectedSourceSystemRegister.sourceSystem.sourceSystemName}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default BatchRuns;

const sourceSystemColumns: ColumnDef<SourceSystemRegister>[] = [
    {
        header: 'System Name',
        accessorFn: (register: SourceSystemRegister) => register.sourceSystem.sourceSystemShortName,
    },
    {
        header: 'Schema',
        accessorFn: (register: SourceSystemRegister) => register.sourceSystem.schemaName,
    },
    {
        header: 'Batches',
        accessorFn: (register: SourceSystemRegister) => register.batches.length || 0,
    },
];
