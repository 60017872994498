import { Batch, SourceSystem } from './BatchRunModels';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';

import BatchBadge from './BatchBadge';
import BatchRunBreadCrumb from './BatchRunBreadCrumb';
import BatchStatusCounts from './BatchStatusCounts';
import DataTable from '../../Shared/components/DataTable';
import { UserProfileContext } from '../../Shared/contexts/UserProfile';
import { formatDateString } from '../../Shared/SharedHelpers';
import styles from './SourceSystemDetails.module.css';

interface SourceSystemDetailsProps {
    sourceSystem: SourceSystem;
    batches?: Batch[];
    setSelectedSourceSystemKey: Dispatch<SetStateAction<number | null>>;
    setSelectedBatchRun: Dispatch<SetStateAction<Batch | undefined>>;
}

const SourceSystemDetails: React.FC<SourceSystemDetailsProps> = ({
    batches = [],
    sourceSystem,
    setSelectedSourceSystemKey,
    setSelectedBatchRun,
}) => {
    const userProfile = useContext(UserProfileContext);
    
    const formattedBatches = useMemo(
        () =>
            batches.map((batch) => ({
                ...batch,
                resultStatus: batch.resultStatus, //? 1 : 0,
            })),
        [batches]
    );

    const tableColumns: ColumnDef<Batch>[] = useMemo(() => [
        {
            header: 'Filename',
            accessorKey: 'zipFileName',
            cell: ({ getValue }: CellContext<Batch, unknown>) => {
                return getValue() ?? '-';
            },
        },
        {
            header: 'Processed Filename',
            accessorKey: 'processedFileName',
            cell: ({ getValue }: CellContext<Batch, unknown>) => {
                return getValue() ?? '-';
            },
        },
        {
            header: 'Start',
            accessorKey: 'startTime',
            cell: ({ row }: CellContext<Batch, unknown>) => {
                return formatDateString(row.original.startTime, userProfile.environmentConfig.timeZone);
            },
        },
        {
            header: 'End',
            accessorKey: 'endTime',
            cell: ({ row }: CellContext<Batch, unknown>) => {
                return formatDateString(row.original.endTime, userProfile.environmentConfig.timeZone);
            },
        },
        {
            header: 'Status',
            accessorKey: 'resultStatus',
            cell: ({ row }: CellContext<Batch, unknown>) => <BatchBadge status={row.original.resultStatus} />,
        },
        {
            header: 'Summary',
            cell: ({ row }: CellContext<Batch, unknown>) => {
                const processingCount = row.original.batchTables.filter((table) => table.resultStatus == undefined).length;
                const processedCount = row.original.batchTables.filter((table) => table.resultStatus === true).length;
                const errorCount = row.original.batchTables.filter((table) => table.resultStatus === false).length;
    
                return (
                    <BatchStatusCounts
                        processedCount={processedCount}
                        processingCount={processingCount}
                        errorCount={errorCount}
                    />
                );
            },
            sortingFn: (rowA: Row<Batch>, rowB: Row<Batch>, _columnId: string) => {
                const rowAIsGreater =
                    (rowA.original.resultStatus && !rowB.original.resultStatus) ||
                    (rowA.original.resultStatus === null && rowB.original.resultStatus === false);
    
                return rowAIsGreater ? -1 : 1;
            },
        },
    ], [userProfile]);

    return (
        <>
            <div className={styles.container}>
                <BatchRunBreadCrumb
                    setSelectedSourceSystemKey={setSelectedSourceSystemKey}
                    setSelectedBatchRun={setSelectedBatchRun}
                    sourceSystemName={sourceSystem.sourceSystemName}
                    batchName={undefined}
                />

                <div className={styles.content}>
                    <h4 className={styles.title}>{sourceSystem.sourceSystemName}</h4>

                    <div>
                        <span className={styles.label}>System Short Name: </span>
                        <span className={styles.text}>{sourceSystem.sourceSystemShortName}</span>
                    </div>

                    <div>
                        <span className={styles.label}>Schema Name: </span>
                        <span className={styles.text}>{sourceSystem.schemaName}</span>
                    </div>
                </div>
            </div>

            <DataTable
                label="batch runs"
                hasTopRadius={false}
                data={formattedBatches}
                columns={tableColumns}
                loading={false}
                onRecordClick={(row) => {
                    setSelectedBatchRun(row);
                }}
                recordsAreClickable
            />
        </>
    );
};

export default SourceSystemDetails;

