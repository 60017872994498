/* eslint-disable @typescript-eslint/no-empty-function */

import { EnvironmentConfig, SourceSystem, UserProfile, UserRoles } from '../SharedModels';
import { createContext, useCallback, useEffect, useState } from 'react';

import ApiHelper from '../ApiHelper';
import { FavoriteReport } from '../../Reports/ReportModels';
import { User } from '../../TeamMembers/TeamMemberModels';
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from '@azure/msal-react';

export const DefaultUserProfile: UserProfile = {
    favoriteReports: [],
    addFavoriteReport: () => {},
    removeFavoriteReport: () => {},
    cdnToken: '?',
    sourceSystems: [],
    setSourceSystems: () => {},
    isLoading: false,
    setIsLoading: () => {},
    isEccoviaUser: false,
    environmentConfig: {},
    failedToLoad: false,
};
export const ECCOVIA_CUSTOMERID = 'eccovia';

export const UserProfileContext = createContext<UserProfile>(DefaultUserProfile);

export function useUserProfile(): UserProfile {
    const [favoriteReports, setFavoriteReports] = useState<FavoriteReport[]>([]);
    const [sourceSystems, setSourceSystems] = useState<SourceSystem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [user, setUser] = useState<User>();
    const [environmentConfig, setEnvironmentConfig] = useState<EnvironmentConfig>({});
    const [cdnToken, setCdnToken] = useState('?');

    const [api] = useState(new ApiHelper());
    const { instance, accounts } = useMsal();

    const isEccoviaUser = instance.getAllAccounts()[0]?.idTokenClaims?.extension_customerId === ECCOVIA_CUSTOMERID;

    const onIdle = useCallback(() => {
        const netApiLogout = api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            process.env.REACT_APP_NET_API_URL + '/User/logout',
            'DELETE'
        );

        let pythonApiLogout = Promise.resolve<Response>(new Response());
        if (isUserSourceSystemAdminOrHigher(user, isEccoviaUser)) {
            pythonApiLogout = api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_PYTHON_API_URL + '/logout',
                'DELETE'
            );
        }

        Promise.all([netApiLogout, pythonApiLogout]).finally(() => {
            instance.logout();
        });
    }, [api, instance, isEccoviaUser, user]);

    // 1000 (ms) * 60 (seconds) * 30 (minutes)
    useIdleTimer({ onIdle, timeout: 1000 * 60 * 30 });

    const initializeUserProfile = useCallback(() => {
        if (accounts.length > 0) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/UserProfile/favreports'
            ).then(async (result: Response) => {
                const favorites: FavoriteReport[] = await result.json();
                setFavoriteReports(favorites);
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/sourcesystems/true'
            ).then(async (result: Response) => {
                const sources: SourceSystem[] = await result.json();
                setSourceSystems(sources);
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/CdnToken'
            ).then(async (result: Response) => {
                const t = await result.text();
                setCdnToken(t);
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/UserProfile/environmentconfig'
            ).then(async (result: Response) => {
                const env = await result.json();
                setEnvironmentConfig((prev) => {
                    return { ...prev, ...env };
                });
            });

            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/customer'
            ).then(async (result: Response) => {
                const customer = await result.json();
                setEnvironmentConfig((prev) => {
                    return { ...prev, ...customer };
                });
            });
        }
    }, [accounts.length, api, instance]);

    const verifyUserProfileCreation = useCallback(() => {
        if (accounts.length > 0) {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + '/User/me'
            )
                .then(async (result: Response) => {
                    const user: User = await result.json();
                    setUser(user);
                })
                .catch(() => setFailedToLoad(true));
        }
    }, [accounts.length, api, instance]);

    useEffect(() => {
        if (!isLoading && accounts.length > 0) {
            if (user) {
                initializeUserProfile();
            } else {
                verifyUserProfileCreation();
            }
        } else if (isLoading && user) {
            setUser(undefined);
        }
    }, [accounts.length, api, isLoading, initializeUserProfile, instance, user, verifyUserProfileCreation]);

    const saveFavoritesList = useCallback(
        (favorites: FavoriteReport[]) => {
            api.callApi(
                instance,
                [process.env.REACT_APP_B2C_SCOPE ?? ''],
                process.env.REACT_APP_NET_API_URL + `/UserProfile/favreports`,
                'PUT',
                JSON.stringify(favorites.map((favorite) => favorite.reportKey))
            );
        },
        [api, instance]
    );

    const addFavoriteReport = useCallback(
        (reportToAdd: FavoriteReport): void => {
            setFavoriteReports((prev) => {
                const newValue = [...prev, reportToAdd];
                saveFavoritesList(newValue);

                return newValue;
            });
        },
        [saveFavoritesList]
    );

    const removeFavoriteReport = useCallback(
        (reportToRemove: FavoriteReport): void => {
            setFavoriteReports((prev) => {
                const newValue = prev.filter(
                    (favoriteReport) => favoriteReport.reportKey !== reportToRemove?.reportKey
                );
                saveFavoritesList(newValue);

                return newValue;
            });
        },
        [saveFavoritesList]
    );

    return {
        favoriteReports,
        addFavoriteReport,
        removeFavoriteReport,
        cdnToken,
        sourceSystems,
        setSourceSystems,
        isLoading,
        setIsLoading,
        isEccoviaUser,
        user,
        environmentConfig,
        failedToLoad,
    };
}

export function isSourceSystemAdminOrHigher(userProfile: UserProfile): boolean {
    return isUserSourceSystemAdminOrHigher(userProfile.user, userProfile.isEccoviaUser);
}

export function isUserSourceSystemAdminOrHigher(user: User | undefined, isEccoviaUser: boolean): boolean {
    const value = user?.roleKey && user.roleKey <= UserRoles['Source System Admin'];

    return !!value || isEccoviaUser;
}

export function isOrgAdminOrHigher(userProfile: UserProfile): boolean {
    const value = userProfile.user?.roleKey && userProfile.user.roleKey <= UserRoles['Org Admin'];
    return !!value || userProfile.isEccoviaUser;
}

export function isGlobalAdmin(userProfile: UserProfile): boolean {
    return userProfile.user?.roleKey === UserRoles['Global Admin'] || userProfile.isEccoviaUser;
}

export function isClientTrackCustomer(userProfile: UserProfile) : boolean {
    return userProfile.environmentConfig.clientTrackUrl !== undefined && userProfile.environmentConfig.clientTrackUrl.length !== 0;
}
